@import 'styles/vars';

$title-size: 26px;
$title-height: 30px;
$title-padding: 20px;

.page-title {
  display: flex;

  position: relative;

  &:not(.no-style) {
    font-weight: bold;
    color: #1d2650;
    //color: #a7aec1;
    margin: 0px;
    min-height: $title-height;
    box-sizing: border-box;
    line-height: normal;
    font-size: 26px;
    align-items: flex-end;
    margin-bottom: $title-padding;

    &.gradient {
      color: #ffffff;
    }
  }
  &.gradient {
    margin-bottom: $title-padding * 2;
  }

  &.center {
    justify-content: center;
    text-align: center;
    align-self: center;
    align-items: center;
  }

  &.big {
    font-size: 50px;
    font-weight: bold;
  }
}

@mixin gradient-height($size) {
  &.gradient-20 {
    min-height: calc($size + 20px);
  }

  &.gradient-20 {
    min-height: calc($size + 20px);
  }

  &.gradient-40 {
    min-height: calc($size + 40px);
  }

  &.gradient-50 {
    min-height: calc($size + 50px);
  }

  &.gradient-60 {
    min-height: calc($size + 60px);
  }

  &.gradient-80 {
    min-height: calc($size + 80px);
  }

  &.gradient-100 {
    min-height: calc($size + 100px);
  }

  &.gradient-120 {
    min-height: calc($size + 120px);
  }

  &.gradient-140 {
    min-height: calc($size + 140px);
  }

  &.gradient-200 {
    min-height: calc($size + 140px);
  }
}

.mobile {
  .header-gradient {
    @include gradient-height(
      calc($header-gradient-height + $header-gradient-bottom)
    );
  }

  .page-title {
    @include gradient-height($title-height);

    &.gradient {
      margin-bottom: $title-padding * 2 + ($header-gradient-bottom * 0.25);
    }
  }
}

.header-gradient {
  @include gradient-height($header-gradient-height);
}

.page-title {
  @include gradient-height($title-height);
}

.page-title {
  .btn.header {
    max-width: 160px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    max-height: 40px;
    margin-left: 10px;

    &:hover {
      opacity: 0.9;
    }
  }
}
