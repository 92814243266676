@import 'styles/vars';

.staff-special-dates-list {
  .grid-item {
    margin-bottom: 20px;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
  }

  .grid-field {
    justify-content: center;
  }

  .special-date-item {
    display: flex;
    flex: 1;

    box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 20px;

    &:hover {
      box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.3);
    }

    .info {
      display: flex;
      flex-direction: column;

      .label {
        color: #707483;
        font-size: 14px;
        margin-right: 5px;
      }

      .status {
        color: $base-color;

        &.opened {
          color: #28e0b6;
        }

        &.closed {
          color: #da3705;
        }
      }

      .date {
        color: $base-color;
        font-size: 14px;
      }
    }
  }
}
