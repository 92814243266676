@import 'styles/vars';

.items-list {
  display: flex;
  width: 100%;
  flex-direction: column;

  &.is-loading .grid-container,
  &.is-no-items .grid-container {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .grid-item {
    opacity: 1;
    animation: 0.4s ease-in 0s 1 show-in;
  }

  &.table {
    .grid-item {
      &.header {
        background-color: #a1a3a6;
        padding: 5px;
      }

      &:not(.header) {
        padding: 5px;
      }

      &:not(.header):nth-child(even) {
        background-color: #e1e5e8;
      }

      &:not(.header):nth-child(odd) {
        background-color: #cbcfd2;
      }
    }
  }

  .no-items {
    color: $base-color;
    font-size: 17px;
    align-self: center;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .pagination-results {
    flex: 1;
  }

  .pagination-buttons {
    display: flex;

    &.infinite-scroll {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .button-load-more {
      cursor: pointer;
      background-color: #ffffff;
      border: 0px;
      color: #000000;
      box-sizing: border-box;
      padding: 10px 20px;
      text-transform: uppercase;
      font-weight: 600;

      &:hover {
        opacity: 0.7;
      }
    }

    .button-previous {
      margin-right: 5px;
    }

    /* .button-next {
      
    } */
  }
}
