@import 'styles/vars';
@import 'styles/Form/Theme';

.autocomplete-container {
  display: flex;
  flex-direction: column;

  .input {
    position: relative;
    z-index: 2;
  }

  .results-container {
    display: flex;
    position: relative;

    .results {
      display: flex;
      position: absolute;
      top: -$space-between-input;
      box-sizing: border-box;
      background-color: #ffffff;
      flex-direction: column;
      box-sizing: border-box;
      z-index: 1;

      width: 100%;

      .item-label {
        display: flex;
        box-sizing: border-box;
      }

      .item-value {
        display: flex;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }

  &.default {
    .results-container {
      .results {
        max-height: 300px;
        overflow-y: scroll;
        background-color: #ffffff;
        border: 1px solid $separator-color;

        .item-label {
          padding: 15px 10px 5px 10px;

          > .text {
            color: #747b9f;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .item-value {
          border-bottom: 1px solid $separator-color;
          padding: 10px 10px;

          &:hover {
            background-color: $blue-background;
          }

          > .new-item {
            display: flex;
            width: 100%;
            > .text {
              font-weight: 500;
              font-size: 14px;
              color: #037aff;
            }
          }

          > .text {
            display: flex;
            flex-direction: column;

            .name {
              color: $base-color;

              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .desc {
              color: $gray-color;

              font-size: 11px;
              margin-top: 3px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
