@import 'styles/vars';

@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: 300;
  src: local('Proxima'), url('./Fonts/proxima-nova-thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: italic;
  font-weight: 300;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-thin-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: italic;
  font-weight: normal;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-regular-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: 500;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: italic;
  font-weight: 500;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-semibold-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: bold;
  src: local('Proxima'), url('./Fonts/proxima-nova-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: italic;
  font-weight: bold;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-bold-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: 800;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-extrabold.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: italic;
  font-weight: 800;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-extrabold-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: 900;
  src: local('Proxima'), url('./Fonts/proxima-nova-black.woff2') format('woff2');
}

@font-face {
  font-family: 'Proxima';
  font-style: italic;
  font-weight: 900;
  src: local('Proxima'),
    url('./Fonts/proxima-nova-black-italic.woff2') format('woff2');
}

button,
textarea {
  font-family: 'Proxima';
}

body {
  margin: 0;
  font-family: Proxima, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

#app {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: #ffffff;
}

#app-content {
  flex: 1;
  z-index: 1;
}

.base-size {
  display: flex;
  max-width: $page-max-width;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.mobile .base-size {
  max-width: 100vw;
  width: 100vw;
}

.expand {
  display: flex;
  flex: 1;
}

.expand-full {
  display: flex;
  flex: 1;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.align-start {
  align-self: flex-start !important;
}

.align-end {
  align-self: flex-end !important;
}

.align-bottom {
  align-self: flex-start !important;
}

.align-center {
  align-self: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-none {
  flex: none;
}

.fill {
  width: 100%;
}

.center {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
}

.padding {
  box-sizing: border-box;
  padding: $base-padding;
}
.margin {
  margin: $base-padding;
}

.top-padding {
  box-sizing: border-box;
  padding-top: $base-padding !important;
}
.top-margin {
  margin-top: $base-padding !important;
}

.left-padding {
  box-sizing: border-box;
  padding-left: $base-padding !important;
}
.left-margin {
  margin-left: $base-padding !important;
}

.right-padding {
  box-sizing: border-box;
  padding-right: $base-padding !important;
}
.right-margin {
  margin-right: $base-padding !important;
}

.bottom-padding {
  box-sizing: border-box;
  padding-bottom: $base-padding !important;
}
.bottom-margin {
  margin-bottom: $base-padding !important;
}

.vertical-padding {
  box-sizing: border-box;
  padding-top: $base-padding !important;
  padding-bottom: $base-padding !important;
}

.horizontal-padding {
  box-sizing: border-box;
  padding-left: $base-padding !important;
  padding-right: $base-padding !important;
}

.vertical-padding {
  box-sizing: border-box;
  padding-top: $base-padding !important;
  padding-bottom: $base-padding !important;
}

.blue-background {
  background-color: $blue-background;
}

.block-gradient {
  background: $block-gradient;
}

.max-100 {
  max-width: 100px;
}

.max-200 {
  max-width: 200px;
}

.max-300 {
  max-width: 300px;
}

.max-400 {
  max-width: 400px;
}

.max-500 {
  max-width: 500px;
}

.max-600 {
  max-width: 600px;
}

.max-700 {
  max-width: 700px;
}

.max-800 {
  max-width: 800px;
}

.max-900 {
  max-width: 900px;
}

.max-1000 {
  max-width: 1000px;
}

.show-in {
  opacity: 1;
  animation: 0.3s ease-in 0s 1 show-in;
}

br {
  line-height: normal;
}

input {
  caret-color: $base-color;
}
