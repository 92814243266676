@import 'styles/vars';
@import 'components/Staff/Tabbar/Tabbar';

$staff-container-radius: 10px;

.desktop {
  .staff-container {
    border-top-left-radius: $staff-container-radius;
    border-top-right-radius: $staff-container-radius;
  }

  .staff-container > .base-size {
    background-color: #ffffff;

    border-top-left-radius: $staff-container-radius;
    border-top-right-radius: $staff-container-radius;
  }

  #app-header {
    &.staff-header {
      background: #262c48;

      .logo {
        margin-left: 38px;
      }
    }
  }
}

.mobile {
  .staff-container {
    background-color: #ffffff;
  }
}

.staff-panel {
  display: flex;
  width: 100%;
  background-color: #262c48;
  flex-direction: column;
}

.modal {
  .staff-title {
    font-size: 20px;
    margin: 0px;
    padding-top: 0px;
  }
}

.staff-title {
}

.mobile {
  .staff-container {
    padding-bottom: $tabbar-height;
  }

  .staff-panel:not(.pending) {
    .staff-container {
      .form .actions {
        bottom: $tabbar-height;
      }
    }
  }
}

.staff-container {
  min-height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Overwrite */
$staff-base-padding: 30px;

.desktop {
  .staff-panel {
    .padding {
      padding: $staff-base-padding;
    }
    .margin {
      margin: $staff-base-padding;
    }

    .top-padding {
      padding-top: $staff-base-padding !important;
    }
    .top-margin {
      margin-top: $staff-base-padding !important;
    }

    .left-padding {
      padding-left: $staff-base-padding !important;
    }
    .left-margin {
      margin-left: $staff-base-padding !important;
    }

    .right-padding {
      padding-right: $staff-base-padding !important;
    }
    .right-margin {
      margin-right: $staff-base-padding !important;
    }

    .bottom-padding {
      padding-bottom: $staff-base-padding !important;
    }
    .bottom-margin {
      margin-bottom: $staff-base-padding !important;
    }

    .vertical-padding {
      padding-top: $staff-base-padding !important;
      padding-bottom: $staff-base-padding !important;
    }

    .horizontal-padding {
      padding-left: $staff-base-padding !important;
      padding-right: $staff-base-padding !important;
    }

    .vertical-padding {
      padding-top: $staff-base-padding !important;
      padding-bottom: $staff-base-padding !important;
    }
  }
}
