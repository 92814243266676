@import 'styles/vars';

@mixin loadingColor($color: $base-color-secondary) {
  border: 3px solid $color;
  border-top: 3px solid transparent;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  display: flex;
  padding: 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  opacity: 1;

  &.size-small > .label {
    font-size: 18px;
  }
  &.size-medium > .label {
    font-size: 22px;
  }
  &.size-large > .label {
    font-size: 26px;
  }

  &.size-small > .circle {
    width: 20px;
    height: 20px;
  }
  &.size-medium > .circle {
    width: 40px;
    height: 40px;
  }
  &.size-large > .circle {
    width: 80px;
    height: 80px;
  }

  .circle {
    border-radius: 50%;
    display: inline-block;
    animation: rotating 700ms linear infinite;
    @include loadingColor();
  }

  .label {
    margin-bottom: 10px;
    font-size: 18px;
    color: $base-color;
  }
}
