@import 'styles/vars';

.staff-share {
  display: flex;
  flex-direction: column;

  .share-item {
    .headline {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: $base-color;
      }
      .sub {
        font-size: 14px;
        color: $base-color;
      }
    }
  }

  .share-input {
    cursor: pointer;
    display: flex;
    border: 0px;
    font-size: 16px;
    font-weight: 500;
    max-width: 400px;
    height: 50px;
    line-height: 50px;
    background-color: #f5faff;
    outline: none;
    border-radius: 25px;
    padding: 0px 60px 0px 15px;
    box-sizing: border-box;
    width: 100%;

    & {
      flex: 1;
      position: relative;
      background-image: url('./Assets/Copy.svg');
      background-size: 50px;
      background-position: right center;
      background-repeat: no-repeat;
      margin-bottom: 0px;
    }

    &::selection {
      background-color: transparent;
    }

    &:active {
      border: 0px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
