@import 'styles/vars';

.desktop {
  .business-reviews {
    .title {
      font-size: 22px;
      margin-bottom: 15px;
    }
    .review-item {
      .top {
        .user {
          font-size: 16px;
        }
        .date {
          font-size: 14px;
        }
        .rating {
          .star {
            width: 16px;
            height: 16px;
            margin-left: 4px;
          }
        }
      }
      .comment {
        font-size: 18px;
      }
    }
  }
}

.business-reviews {
  display: flex;
  flex-direction: column;

  .grid-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .grid-container {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
    color: $gray-color;
  }

  .review-item {
    display: flex;
    flex-direction: column;
    flex: 1;

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .user {
        color: $gray-color;
        font-weight: bold;
        font-size: 14px;
        line-height: normal;
      }
      .date {
        color: $base-color;
        font-size: 10px;
        flex: 1;
        margin-right: 10px;
        line-height: normal;
        text-align: right;
      }
      .rating {
        display: flex;
        .star {
          width: 10px;
          height: 10px;
          margin-left: 2px;
        }
      }
    }

    .comment {
      margin-top: 5px;
      color: $base-color;
      font-size: 13px;
      line-height: 18px;
      word-break: break-word;
    }
  }
}
