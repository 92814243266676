@import 'styles/vars';

.mobile {
  .staff-clients-list {
    .client-item {
      position: relative;
      flex-direction: column;

      .options-field {
        margin-top: 20px;
        justify-content: center;
      }

      .dropdown-container {
        position: absolute;
        top: 25px;
        right: 20px;
      }
      .info {
        margin-top: 20px;
        align-items: flex-start;
      }
    }
  }
}

.staff-clients-list {
  .grid-item {
    margin-bottom: 20px;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
  }

  .grid-field {
    justify-content: center;
  }

  .client-item {
    display: flex;
    flex: 1;

    box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 20px;

    &:hover {
      box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.3);
    }

    .options-field {
      justify-content: flex-end;
      .reserve-link {
        max-width: 40px;
        align-self: center;
        margin-right: 20px;
      }
      .dropdown-container {
      }
    }

    .client {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: flex-start;

      .name {
        color: $base-color;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
      }

      .phone,
      .email {
        color: #707483;
        font-size: 13px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .item {
        align-items: center;
        display: flex;

        &:first-child {
          margin-bottom: 15px;
        }

        .label {
          color: #707483;
          font-size: 14px;
          margin-right: 5px;
        }

        .value {
          color: $base-color;
          font-size: 14px;
        }
      }
    }
  }
}
