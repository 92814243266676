@import 'styles/vars';

@keyframes modal-opening {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modals-list {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;
}
.modal {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(116, 123, 159, 0.7);
  width: 100vw;
  height: 100vh;
  opacity: 1;
  animation: 0.2s ease-in 0s 1 modal-opening;
  transition: opacity 0.25s ease-in, background 0.25s ease-in;
}

.modal.closing {
  transition-timing-function: ease-out;
  opacity: 0;
  background: rgba(116, 123, 159, 0);
}

:global(.modal-page-container) {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: $base-padding;

  :global(.modal-page) {
    display: flex;
    background-color: #ffffff;
    border-radius: 10px;
    flex-direction: column;
    min-width: 200px;
    max-width: 600px;
    margin: auto;
    margin-top: calc(10vh - ($base-padding * 2));
    max-height: calc(90vh - ($base-padding * 2));
    flex: none;
    box-sizing: border-box;

    > :global(.header) {
      display: flex;
      height: 45px;
      justify-content: flex-end;
      align-items: center;
      flex: none;

      .close-button {
        user-select: none;
        cursor: pointer;
        color: $base-color;
        font-size: 17px;
        font-weight: 500;
        height: 100%;
        background-color: transparent;
        border: 0px;
        padding: 0px;
        padding-left: $base-padding;
        padding-right: $base-padding;
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      overflow-y: scroll;
    }
  }
}

.mobile {
  .modal-page-container {
    .modal-page {
      min-width: none;
      max-width: none;
      margin-top: 0px;
      max-height: calc(100vh - $base-padding * 2);
      max-width: calc(100vw - $base-padding * 2);
      width: 100%;

      .base-size {
        max-width: 100%;
      }
    }
  }
}
