@import 'styles/vars';
@import 'components/Common/Button/Button';

$categories-header-height: 50px;

.services-not-available {
  color: $gray-color;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
}

.desktop {
  .booking-categories-list {
    .categories-header-container {
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: transparent;
      }

      &:hover ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $gray-color !important;
      }

      .categories-header {
        .button:first-child {
          padding-left: 0px;
        }
        .button:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.desktop {
  .categories-header-container {
    padding-left: $base-padding;

    .arrow {
      &.left {
        margin-right: $base-padding;
      }
      &.right {
        margin-left: $base-padding;
      }
    }
  }
}

.categories-header-container {
  position: sticky;
  top: $header-height;
  z-index: 1;
  background: #ffffff;

  .arrow {
    margin-top: 5px;

    cursor: pointer;
    width: 50px;
    height: $categories-header-height;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    flex: none;

    &.left {
      background-image: url('../Assets/ArrowLeft.svg');
      &:hover {
        background-image: url('../Assets/ArrowLeftActive.svg');
      }
    }
    &.right {
      background-image: url('../Assets/ArrowRight.svg');
      &:hover {
        background-image: url('../Assets/ArrowRightActive.svg');
      }
    }
  }
}

.booking-categories-list {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 100vw;

  .categories-container {
  }
  /* Categories header*/
  .categories-header {
    position: relative;
    display: flex;
    box-sizing: border-box;
    padding: 5px 0px 10px 0px;
    align-items: center;
    overflow-x: scroll;
    flex: 1;

    /*-ms-overflow-style: none;
    scrollbar-width: auto;

    &::-webkit-scrollbar {
      display: none;
    }*/

    .button {
      user-select: none;
      cursor: pointer;
      padding: 0px;
      padding-left: $base-padding;
      scroll-margin-left: $base-padding;
      display: flex;
      border: 0px;
      color: $base-color;
      font-size: 18px;
      height: $categories-header-height;
      text-align: center;
      box-sizing: border-box;
      line-height: normal;
      border-radius: 5px;
      flex-shrink: 0;
      white-space: nowrap;
      background-color: transparent;
      align-items: center;

      &:last-child {
        margin-right: $base-padding;
      }

      .name {
        max-width: 250px;
        min-width: 140px;
        font-size: 20px;
        padding: 0px 18px;
        transition: max-height 0.1s ease-in, background 0.1s ease-in;
        box-sizing: border-box;
        margin: 0px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: #eff4f9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        max-height: 40px;
        line-height: 40px;
      }

      &:hover {
        .name {
          background: #dbe0e5;
        }
      }

      &.active {
        .name {
          max-height: $categories-header-height;
          line-height: $categories-header-height;
          background: $btn-blue-gradient;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }

  /* Category */
  .category-item {
    scroll-margin-top: calc($header-height + 75px);
    margin-top: 10px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0px;
    }

    .header {
      margin-bottom: 15px;

      .name {
        font-weight: bold;
        font-size: 18px;
        color: $gray-color;
      }
    }
  }

  /* Services */
  .services-list {
    box-sizing: border-box;

    .service-item {
      -webkit-tap-highlight-color: transparent;

      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      padding-bottom: 11px;
      padding-top: 11px;

      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
      }

      .check {
        height: 24px;
        width: 24px;
        border: 1px solid $gray-color;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        position: relative;
      }
      .check::after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        border: 0px;
        background-size: cover;
        background-image: url('./Assets/RadioCheck.svg');
        background-repeat: no-repeat;
        background-position: 0px 0px;
        opacity: 0;
        transition: all 0.1s ease-in;
      }
      &.selected .check {
        border: 0px;
      }
      &.selected .check::after {
        opacity: 1;
      }
      .content {
        flex: 1;
        padding-left: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .top {
          display: flex;

          .name {
            flex: 1;
            word-break: break-word;
            font-size: 18px;
            font-weight: bold;
            color: $base-color-secondary;
          }
          .price {
            font-size: 18px;
            color: $base-color-secondary;
          }
        }
        .time {
          font-size: 14px;
          color: #6f7283;
        }
        .notes {
          margin-top: 10px;
          word-break: break-word;
          font-size: 12px;
          color: $base-color-secondary;
        }
      }
    }
  }
}
