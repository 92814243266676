@import 'styles/vars';

.login-terms {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: $gray-color;
  box-sizing: border-box;

  font-size: 12px;
  padding: $base-padding;

  > .text {
    text-align: center;
    line-height: 20px;
  }

  a {
    color: $base-color;
    text-decoration: underline;
  }
}
