@import 'styles/vars';

/*@keyframes menu-display {
  0% {
    top: 100vh;
  }
  100% {
    top: 0px;
  }
}*/

.mobile {
  #app-header {
    .links {
      flex: 1;
    }
  }
}

#app-header {
  z-index: 500;
  display: flex;
  justify-content: center;
  height: $header-height;
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  transition: all 0.15s ease-in-out;

  .image.dark,
  .image.light {
    transition: opacity 0.15s ease-in-out;
  }

  .image.dark {
    position: absolute;
  }

  &.light:not(.is-not-on-top),
  &.light.forced {
    .image.dark {
      opacity: 0;
    }

    .image.light {
      opacity: 1;
    }
  }

  &.dark {
    .image.dark {
      opacity: 1;
    }

    .image.light {
      opacity: 0;
    }
  }

  &.is-not-on-top:not(.forced) {
    box-shadow: rgb(16 25 40 / 8%) 0px 16px 32px 0px;
    background-color: #ffffff !important;
  }

  &.transparent {
    background-color: transparent;
  }
  .logo {
    transition: all 0.15s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;

    .image {
      padding-left: $base-padding;
    }
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    z-index: 500;

    .header-right {
      display: flex;
      align-self: center;
    }
  }

  &.light .links {
    .link {
      color: #ffffff;
    }
  }
  .links {
    flex: none;
    display: flex;
    box-sizing: border-box;
    padding: 0px 10px;
    justify-content: flex-end;
    align-items: center;

    .link {
      color: $base-color;
      border: 0px;
      background-color: transparent;
      font-weight: bold;
    }
  }

  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    background-color: transparent;
    border: 0px;
    padding-right: $base-padding;
    padding-left: 10px;
  }

  .header-button {
    cursor: pointer;
    box-sizing: border-box;
    border: 0px;
    background-color: transparent;

    &.left {
      padding-right: 10px;
      padding-left: 20px;
    }
    &.right {
      padding-right: 20px;
      padding-left: 10px;
    }
  }
}

#app-menu {
  //animation: 0.15s ease-in 0s 1 menu-display;
  display: flex;
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(237, 244, 255, 1) 70%
  );
  box-sizing: border-box;
  overflow: scroll;

  .content {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    position: relative;
    top: 0px;
    flex-direction: column;
    padding-top: 40px + $base-padding;
    align-items: center;
  }

  .close-button {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    border: 0px;
    padding: 15px;
    padding-right: $base-padding;
    background-color: transparent;

    /*.image {
    }*/
  }

  .user-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;

    .full-name {
      font-size: 20px;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;

    .link {
      border: 0px;
      background-color: transparent;
      color: $base-color;
      font-size: 20px;
      padding: 0px 10px;
      max-width: 290px;
      width: 100%;
      text-align: center;
      transition: transform 0.15s ease-in-out;
      position: relative;
      top: 0px;
      height: 44px;
      border-radius: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;

      &:hover {
        background-color: $base-color;
        color: #ffffff;
      }
    }
  }

  .logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    cursor: pointer;
    color: $base-color-secondary;
    height: 40px;
    margin-top: 20px;
    border-radius: 30px;
    background-color: #ffffff;
    font-size: 18px;
    border: 0px;
    max-width: 180px;
    width: 100%;

    &:hover {
      background-color: #f8f9fc;
    }
  }

  .terms-links {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .link {
      font-size: 16px;
      color: #000000;
      margin-top: 5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.header-gradient {
  top: 0px;
  left: 0px;
  position: absolute;
  color: #ffffff;
  width: 100%;
  overflow: hidden;
  min-height: $header-height;

  &.over {
    z-index: 2;
  }
  &.title-gradient {
    min-height: $header-gradient-height;
  }

  &.blue {
    background: $blue-gradient;
  }

  &.dark-blue {
    background: $dark-blue-gradient;
  }

  &.gray {
    background: $gray-gradient;
  }

  &.dark-gray {
    background: $dark-gray-gradient;
  }

  &.navy-blue {
    background: $navy-blue-gradient;
  }

  &.orange {
    background: $orange-gradient;
  }
}

.mobile {
  .header-gradient {
    &::after {
      position: absolute;
      display: flex;
      content: '';
      border-radius: 30px;
      width: 100%;
      left: 0px;
      height: $header-gradient-bottom * 2;
      bottom: -$header-gradient-bottom;
      background-color: #ffffff;
      z-index: 1;
    }
  }
}
