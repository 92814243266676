@import 'styles/vars';

.desktop {
  .booking-bottom-bar {
    &::after {
      position: absolute;
      content: '';
      display: flex;
      background-color: #cdd3df;
      height: 1px;
      top: 0px;
      left: 0px;
      width: 100%;
    }
    .container {
      background-color: transparent;
    }
  }
}
.booking-bottom-bar {
  position: fixed;
  bottom: 0px;
  background-color: #ffffff;
  left: 0px;
  bottom: -100px;
  transition: bottom 250ms;
  height: 50px;
  width: 100%;

  &.active {
    bottom: 0px;
  }

  .container {
    display: flex;
    max-width: 500px;
    background-color: $field-background;
    height: 100%;
    justify-content: center;
  }
  .services {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    box-sizing: border-box;
    text-align: left;
    color: #3e3e3e;
    padding-left: $base-padding;
    padding-right: $base-padding;
    justify-content: center;

    .count {
      font-size: 14px;
      color: $base-color;
    }
    .price {
      font-size: 16px;
      font-weight: 500;
      color: $base-color;
    }
  }
  .btn {
    height: 100%;
    flex: 0.5;
  }
}
