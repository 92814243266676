@import 'styles/vars';

.desktop {
  .menu-list {
    .menu-button {
      padding-left: $base-padding;
      padding-right: $base-padding;

      &::after {
        right: $base-padding;
      }
    }
  }
}
.menu-list {
  display: flex;
  max-width: 450px;
  flex-direction: column;
  align-self: center;
  width: 100%;

  .menu-button {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: 0px;
    padding: 15px 0px;
    color: $base-color;
    display: flex;
    flex-direction: column;
    position: relative;
    border-top: 1px solid #f2f4fa;

    .label {
      font-size: 18px;
      font-weight: bold;
    }
    .description {
      font-size: 14px;
    }

    &:hover {
      background-color: $blue-background;
    }
    &:first-child {
      border-top: 1px solid #f2f4fa;
    }
    &:last-child {
      border-bottom: 1px solid #f2f4fa;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0px;
      top: 0px;
      height: 100%;
      width: 7px;
      display: flex;
      background-image: url('./Assets/ArrowRight.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }
}
