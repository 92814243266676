$page-max-width: 1024px;

$base-color: #1d2650;

$base-color-secondary: #192660;

$gray-color: #747b9f;

$blue-background: #f5faff;

:root {
  --header-height: 50px;

  .desktop {
    --header-height: 70px;
  }
}

$header-height: var(--header-height);

.mobile {
  $header-height: 50px !default;
}

$base-padding: 16px;

$header-gradient-bottom: 25px;

$header-gradient-height: calc($header-height + 70px);

$block-gradient: linear-gradient(
  0deg,
  rgba(245, 250, 255, 1) 54%,
  rgba(255, 255, 255, 1) 86%
);

$gray-gradient: linear-gradient(
  90deg,
  rgba(127, 143, 175, 1) 0%,
  rgba(181, 193, 216, 1) 100%
);

$dark-gray-gradient: linear-gradient(
  90deg,
  rgba(59, 84, 100, 1) 0%,
  rgba(98, 122, 147, 1) 100%
);

$blue-gradient: linear-gradient(
  90deg,
  rgba(75, 95, 219, 1) 0%,
  rgba(105, 145, 255, 1) 100%
);

$dark-blue-gradient: linear-gradient(90deg, #1d2650, #313b6b);

$navy-blue-gradient: linear-gradient(
  90deg,
  rgba(41, 152, 178, 1) 0%,
  rgba(86, 222, 212, 1) 100%
);

$orange-gradient: linear-gradient(
  90deg,
  rgba(219, 115, 60, 1) 0%,
  rgba(237, 179, 100, 1) 100%
);

$separator-color: rgba(191, 197, 224, 0.5);

$field-background: #edf3f7;

$base-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.1);
$hover-base-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.3);

@keyframes show-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
