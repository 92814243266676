@import 'styles/vars';

.mobile .appointments-list {
  .appointment-item {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;

    .date-status {
      flex-direction: row;
      padding: 0px;
      margin: 0px;
      margin-top: 0px;
      margin-bottom: 20px;

      .date {
        font-size: 16px;
        flex: 1;
        margin-bottom: 0px;
      }
    }

    .info {
      flex-direction: row;
      border-top: 1px solid #f2f4fa;
      margin-top: 20px;
      padding: 0px;
      padding-top: 10px;
      align-items: center;

      .item {
        flex: 1;
        margin: 0px;
        align-items: center;

        &:first-child {
          margin-bottom: 0px;
        }

        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
}

.appointments-list {
  .grid-item {
    margin-bottom: 20px;
  }

  .grid-container {
    display: flex;
    flex-direction: column;
  }

  .grid-field {
    justify-content: center;
  }

  .appointment-item {
    display: flex;
    flex: 1;

    box-shadow: $base-shadow;
    border-radius: 10px;
    padding: 25px 20px;

    &:hover {
      box-shadow: $hover-base-shadow;
    }

    .client {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-self: flex-start;

      .name {
        color: $base-color;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 5px;
      }

      .phone,
      .email {
        color: #707483;
        font-size: 13px;
      }
    }

    .business {
      flex: 3;
      display: flex;
      align-items: center;

      .photo {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        border-radius: 10px;
        object-fit: cover;
      }

      .name {
        color: $base-color;
        font-size: 18px;
        font-weight: 500;
      }
      .address {
        color: #747b9f;
        font-size: 14px;
      }
    }

    .date-status {
      flex: 1.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      .date {
        display: flex;
        align-items: center;
        color: $base-color;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
      }
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .item {
        align-items: center;
        display: flex;

        &:first-child {
          margin-bottom: 15px;
        }

        .label {
          color: #707483;
          font-size: 14px;
          margin-right: 5px;
        }

        .count {
          color: $base-color;
          font-size: 16px;
        }
        .total {
          color: $base-color;
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }
}
