@import 'styles/vars';
@import 'components/Staff/Tabbar/Tabbar';

@keyframes floating-buttons-show-up {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 0px;
  }
}

.modal > .floating-buttons {
  animation: 0.25s ease-in 0s 1 floating-buttons-show-up;
}

.mobile {
  .staff-container {
    .floating-buttons {
      bottom: $tabbar-height;
    }
  }

  .floating-buttons {
    &.floating-container {
      right: 0px;
    }
  }
}

.floating-buttons {
  z-index: 2;
  position: fixed;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-out;
  opacity: 1;

  &.floating-container {
    right: calc((100% - $page-max-width) / 2);
  }

  &.modal-visible {
    opacity: 0;
    bottom: -10px;
  }

  .floating-button {
    padding: 0px;
    border: 0px;
    color: #ffffff;
    font-size: 17px;
    cursor: pointer;
    margin-top: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-self: flex-end;

    &:hover {
      opacity: 0.95;
    }

    &.option-button > .icon {
      background-color: base-color-secondary;
    }

    .text {
      background-color: red;
      border-radius: 4px;
      align-self: center;
      font-size: 15px;
      margin-right: 12px;
      color: #ffffff;
      background-color: #404753;
      font-weight: 500;
      padding: 8px 10px;
    }

    .icon {
      background-color: $base-color;
      height: 56px;
      width: 56px;
      border-radius: 100%;
      align-self: flex-end;
    }
  }
}
