@import 'styles/vars';

.desktop {
  .business-bottom-bar {
    &::after {
      position: absolute;
      content: '';
      display: flex;
      background-color: $separator-color;
      height: 1px;
      top: 0px;
      left: 0px;
      width: 100%;
    }
  }
}

.business-bottom-bar {
  position: sticky;
  bottom: 0px;
  background-color: #ffffff;
  height: 50px;

  .container {
    display: flex;
    max-width: 500px;
    height: 100%;
    background-color: $field-background;
    align-items: center;
    .services {
      flex: 0.6;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #3e3e3e;
    }
    .btn {
      flex: 0.4;
      height: 100%;
    }
  }
}
