@import 'styles/vars';

@keyframes dropdown-animation-mobile-up {
  0% {
    bottom: -40px;
  }
  100% {
    bottom: 0px;
  }
}

@keyframes dropdown-animation-mobile-down {
  00% {
    bottom: 0px;
  }
  100% {
    bottom: -40px;
  }
}

@keyframes dropdown-animation-desktop-down {
  0% {
    margin-top: 25px;
  }
  100% {
    margin-top: 30px;
  }
}

.dropdown-container {
  display: flex;
  position: relative;
  align-self: center;
}

.dropdown-button {
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  padding: 0px;
}

.dropdown-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  animation: 0.1s ease-in 0s 1 dropdown-animation-desktop-down;
  box-sizing: border-box;
  padding: $base-padding;

  .options {
    background-color: #ffffff;
    border-radius: 4px;
    overflow: hidden;

    .option-button {
      cursor: pointer;
      border: 0px;
      display: flex;
      color: $base-color;
      font-weight: 500;
      border-bottom: 1px solid rgb($gray-color, 0.3);
      padding: 10px 10px;
      text-align: center;
      background-color: transparent;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      font-size: 17px;

      &:hover {
        background-color: #edeff3;
      }
      .text {
        flex: 1;
      }

      &:last-child {
        border-bottom: 0px;
      }
      &:last-child {
        border-bottom: 0px;
      }
    }
  }

  .close {
    display: none;
    margin-top: 10px;
  }
}

.desktop {
  .dropdown-menu {
    margin-top: 30px;
    padding: 0px;
    width: 180px;
    right: 0px;
    z-index: 1;
    box-shadow: 0 2px 5px 0 rgb(164 173 186 / 25%);
  }
}
.mobile {
  .modal.closing {
    .dropdown-menu {
      animation: 0.15s ease-in 0s 1 dropdown-animation-mobile-down;
      bottom: -40px;
    }
  }
  .dropdown-menu {
    position: fixed;
    animation: 0.15s ease-in 0s 1 dropdown-animation-mobile-up;

    width: 100%;
    bottom: 0px;

    .options {
      border-radius: 10px;
      overflow: hidden;

      .option-button {
        padding: 15px 15px;
        font-weight: bold;
      }
    }

    .close {
      display: flex;
    }
  }
}
