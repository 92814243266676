.mobile {
  .card-and-date {
    flex-direction: column;

    .membership-next-payment {
      margin-left: 0px;
      margin-top: 15px;
    }
  }
}

.membership-container {
  display: flex;
  flex-direction: column;
}

.card-and-date {
  display: flex;
  width: 100%;

  .membership-next-payment {
    margin-left: 20px;
  }
}
