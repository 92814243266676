@import 'styles/vars';
@import 'components/Common/Button/Button';

$space-between-input: 10px;
$max-width: 400px;
$field-base-padding: 15px;

.errors-list {
  display: flex;
  flex-direction: column;
  border: 1px solid #ce0606;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 5px 10px;
}
.errors-list > .text {
  box-sizing: border-box;
  padding: 5px 0px;
}

.filter-form {
  margin-bottom: 20px;
}

.input-date {
  position: relative;
}
.date-calendar {
  position: absolute;
}
::placeholder {
  font-family: Proxima;
}

.mobile {
  .modal-page {
    .form {
      &:not(.expand) {
        min-width: auto;
      }

      .actions {
        overflow: hidden;
      }
    }
  }
}

.modal-page {
  .form {
    &:not(.expand) {
      min-width: $max-width;
    }
  }
}

.form {
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:not(.expand) {
    max-width: $max-width;
  }

  &.filter-form {
    max-width: none;
  }

  ::placeholder {
    color: #747b9f;
    font-weight: 400;
    font-size: 14px;
  }

  .inline-error {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: $space-between-input;
  }
  .inline-error > .text {
    color: #da2346;
    font-size: 14px;
  }

  > .input:first-child {
    margin-top: 0px;
  }
  .input {
    box-sizing: border-box;
    color: #1d2650;
    background-color: $field-background;
    padding: $field-base-padding;
    border: 1px solid transparent;
    outline: none;
    font-size: 16px;
    line-height: normal;
    margin-top: $space-between-input;
    margin-bottom: $space-between-input;
    border-radius: 0px;

    &.readonly {
      opacity: 0.5;
    }

    &.input-select {
      position: relative;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      overflow: hidden;
      cursor: pointer;

      background-image: url('./Assets/ArrowDown.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) center;
    }

    &.input-checkbox {
      position: relative;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: #ffffff;

      overflow: hidden;
      cursor: pointer;

      width: 24px;
      height: 24px;
      border: 1px solid #747b9f;
      box-sizing: border-box;
      margin: 0px;
      padding: 0px;

      &:hover {
        opacity: 0.9;
      }
      &:checked {
        border: 0;
        background: linear-gradient(
          90deg,
          rgba(52, 103, 249, 1) 0%,
          rgba(118, 153, 255, 1) 100%
        );

        &::after {
          position: absolute;
          content: '';
          width: 18px;
          height: 16px;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url('./Assets/Check.svg');
          top: 0px;
          left: 8px;
        }
      }
    }
  }
  > .input:last-child {
    margin-bottom: 0px;
  }
  .field:last-child > .input {
    margin-bottom: 0px;
  }
  .input.error {
    border: 1px solid #e9254b;
  }

  .description {
    color: $base-color;
    font-size: 12px;
    margin-bottom: $space-between-input;

    .link {
      color: $base-color;
      font-size: 12px;
      text-decoration: underline;

      &.large {
        display: inline-block;
        font-size: 16px;
        margin-top: 5px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .label {
    display: flex;
    margin-top: $space-between-input;
    color: $gray-color;
    font-size: 14px;
    font-weight: 500;
  }

  .field {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &:first-child > .label {
      margin-top: 0px;
    }

    &.inline {
      user-select: none;
      flex-direction: row;
      flex-wrap: wrap;

      .label {
        cursor: pointer;
        align-self: center;
        margin-top: 0px;
        margin-bottom: $space-between-input;
      }

      .input {
        margin: 0px;
        margin-right: 10px;
        margin-bottom: $space-between-input;
      }
    }
  }

  .actions {
    display: flex;
    position: sticky;
    bottom: 0px;
    margin-top: $space-between-input * 4;
    padding-top: $space-between-input * 2;
    padding-bottom: $space-between-input * 2;
    background-color: #ffffff;
    flex-direction: column;

    &::after {
      position: absolute;
      content: '';
      background-color: #e7e8e9;
      height: 1px;
      display: flex;
      width: 100%;
      top: 0px;
      left: 0px;
    }
  }

  .form-button {
    @include btn;
    margin-bottom: $space-between-input;

    &.form-button:last-child {
      margin-bottom: 0px;
    }
  }
  .submit-button {
    @include btn-primary;
  }
  .remove-button {
    @include btn-transparent;
  }

  textarea {
    resize: vertical;
  }
}

.mobile {
  .padding,
  .horizontal-padding {
    .form {
      .actions {
        &::after {
          width: 100vw;
          left: -$base-padding;
        }
      }
    }
  }
}

/* Filter form */
.form {
  &.filter-form {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .field {
      flex-direction: row;
      align-items: center;
      align-self: center;
    }

    .label {
      font-weight: normal;
      font-size: 14px;
      margin-top: 0px;
      margin-right: 10px;
      white-space: nowrap;
    }

    .input-text {
      border-radius: 25px;
    }

    .input.search {
      flex: 1;
      position: relative;
      margin-right: 15px;
      background-image: url('./Assets/Search.svg');
      background-size: 16px;
      background-position: $field-base-padding center;
      background-repeat: no-repeat;
      padding-left: ($field-base-padding + 16px) + 10px;
      margin-bottom: 0px;

      &:last-child {
        margin-right: 0px;
      }

      &::before {
        content: '';
        position: absolute;
      }
    }

    .input-select {
      margin-top: 0px;
      color: $base-color;
      font-size: 16px;
      background-color: #ffffff;
      border: 1px solid #747c9e;
      border-radius: 5px;
      padding: 10px $field-base-padding;
      align-self: center;
      min-width: 160px;
      width: 100%;
    }
  }
}

.mobile {
  .form {
    &.filter-form {
      flex-direction: column;

      .field {
        align-self: flex-end;
        justify-content: center;
        margin-bottom: 15px;

        .input {
          margin-bottom: 0px;
        }
      }

      .input.search {
        flex: 1;
        margin-right: 0px;
        margin-bottom: 15px;
        width: 100%;
      }

      .input-select {
        flex: 1;
        margin-bottom: 15px;
        max-width: 150px;
        align-self: flex-end;
      }
    }
  }
}
