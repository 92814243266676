@import 'styles/vars';

.business-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  height: 18px;
  font-size: 12px;
  min-width: 70px;
  box-sizing: border-box;

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    padding: 0px 10px;
    text-transform: uppercase;
  }

  &.pending {
    background: $orange-gradient;
  }
  &.active {
    color: $base-color;
    background: #28e0b6;
  }
  &.inactive {
    background: $dark-gray-gradient;
  }
  &.disabled {
    background: #fc4e07;
  }
}
