@import 'styles/vars';
@import 'components/Common/Button/Button';

$dates-list-height: 125px;

.desktop {
  .staff-calendar {
    .staff-dates-list {
      padding-left: $base-padding;
      padding-right: $base-padding;

      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: transparent;
      }

      &:hover ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $gray-color !important;
      }

      .date-month {
        .month {
          left: 0px;
        }

        &:first-child .date-day:first-child {
          margin-left: 0px;
        }

        &:last-child .date-day:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.desktop {
  .staff-dates-list {
    .arrow {
      &.left {
        margin-right: $base-padding;
      }
      &.right {
        margin-left: $base-padding;
      }
    }
  }
}

.staff-dates-list {
  z-index: 5;
  position: sticky;
  top: $header-height;
  background: #ffffff;
  display: flex;
  height: 130px;
  flex: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: hidden;

  .arrow {
    margin-top: 42px;

    cursor: pointer;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    flex: none;

    &.left {
      background-image: url('./Assets/ArrowLeft.svg');
      &:hover {
        background-image: url('./Assets/ArrowLeftActive.svg');
      }
    }
    &.right {
      background-image: url('./Assets/ArrowRight.svg');
      &:hover {
        background-image: url('./Assets/ArrowRightActive.svg');
      }
    }
  }
}

.staff-calendar {
  display: flex;
  flex-direction: column;
  max-width: 100vw;

  .not-available {
    color: $gray-color;
  }

  .dates-list {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 0px;
    box-sizing: border-box;
    width: 100%;

    overflow-x: scroll;
    position: sticky;
    top: $header-height;
    background: #ffffff;
    z-index: 1;

    .date-month {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      .month {
        position: sticky;
        left: $base-padding;
        margin-left: 0px;
        margin-bottom: 10px;
        align-self: flex-start;
        color: $base-color;
        font-size: 18px;
        font-weight: 500;
      }

      &:first-child .date-day:first-child {
        margin-left: $base-padding;
      }

      &:last-child .date-day:last-child {
        margin-right: $base-padding;
      }

      .days {
        height: 68px;
        display: flex;
        align-items: center;
        margin-top: 5px;

        .date-day {
          position: relative;
          user-select: none;
          scroll-margin-left: $base-padding;
          -webkit-tap-highlight-color: transparent;
          cursor: pointer;
          flex: none;
          border-radius: 5px;
          margin-right: 10px;
          box-sizing: border-box;
          background: #f5faff;
          width: 80px;
          max-height: 68px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &.future {
          }

          &.past:not(.selected):not(:hover) {
            opacity: 0.3;
          }

          &.selected {
            max-height: 68px;
            transition: max-height 0.1s ease-in, background 0.1s ease-in,
              font-size 0.1s ease-in;
          }

          .weekday {
            font-size: 14px;
          }
          .day {
            font-size: 26px;
            font-weight: bold;
          }

          &:hover:not(.selected) {
            border: 1px solid rgba($base-color, 0.29);
          }
          &:last-child {
            margin-right: 0px;
          }

          &:not(.available) {
            background-color: #f5faff;
          }
          &:not(.available) .text {
            color: rgba($base-color, 0.29);
          }

          &.selected {
            background: linear-gradient(
              0deg,
              rgba(181, 193, 216, 1) 0%,
              rgba(127, 143, 175, 1) 100%
            );

            .weekday {
            }

            .day {
            }

            .text {
              color: #ffffff;
            }
          }

          .today-label {
            color: #ffffff;
            position: absolute;
            font-size: 9px;
            font-weight: 500;
            width: 60px;
            height: 18px;
            bottom: -9px;
            display: flex;
            letter-spacing: 1px;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            background: linear-gradient(
              0deg,
              rgba(73, 92, 219, 1) 0%,
              rgba(105, 145, 255, 1) 100%
            );
            border-radius: 10px;
          }
        }
      }
    }
  }

  .date-hour {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    padding: 25px;
    border-bottom: 1px solid rgba($gray-color, 0.3);

    &:hover {
      background-color: rgba($gray-color, 0.1);
    }
    &:first-child {
      border-top: 1px solid rgba($gray-color, 0.3);
    }
    .time {
      font-weight: 500;
      font-size: 18px;
      color: $base-color;
    }
  }
}
