@import 'styles/vars';

$tabbar-height: 55px;

.tabbar-container {
  position: fixed;
  bottom: 0px;
  display: flex;
  background-color: #ffffff;
  left: 0px;
  width: 100vw;

  height: $tabbar-height;
  border: 1px solid $separator-color;
  z-index: 10;

  .tabbar-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text {
      font-size: 10px;
      color: #747b9f;
      align-items: flex-end;
      margin-top: 5px;
    }

    .icon {
      &::after {
        width: 26px;
        height: 26px;

        background-color: transparent;
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &.icon-calendar::after {
        background-image: url('./Assets/Calendar.svg');
      }
      &.icon-appointments::after {
        background-image: url('./Assets/Appointments.svg');
      }
      &.icon-clients::after {
        background-image: url('./Assets/Clients.svg');
      }
      &.icon-settings::after {
        background-image: url('./Assets/Settings.svg');
      }
    }

    &.active {
      .text {
        color: $base-color;
      }

      .icon {
        &.icon-calendar::after {
          background-image: url('./Assets/CalendarActive.svg');
        }
        &.icon-appointments::after {
          background-image: url('./Assets/AppointmentsActive.svg');
        }
        &.icon-clients::after {
          background-image: url('./Assets/ClientsActive.svg');
        }
        &.icon-settings::after {
          background-image: url('./Assets/SettingsActive.svg');
        }
      }
    }
  }
}
