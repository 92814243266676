@import 'styles/vars';

.mobile {
  .landing-for-business {
    .headline {
      margin-top: 10px;
      margin-bottom: 20px;

      &.main {
        margin-top: 10px;
      }

      .top {
        font-size: 22px;
        line-height: 22px;
        max-width: 240px;
      }
      .bottom {
        max-width: 315px;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .blocks {
      flex-direction: column;
      margin-bottom: 30px;
      .block {
        position: relative;
        margin: 0px;
        padding: 30px 20px;
        max-width: none;
        height: auto;

        &:first-child {
          margin-bottom: 30px;
        }

        .choose-button {
          max-width: 120px;
          position: absolute;
          top: 30px;
          right: 20px;
        }

        .type {
          text-align: left;
          font-size: 28px;
        }
        .price {
          text-align: left;
          font-size: 18px;
        }

        .benefits {
          .benefit {
            font-size: 14px;

            &::before {
              width: 12px;
              height: 12px;
              margin-right: 10px;
              background-size: 10px;
            }
          }
        }
      }
    }
    .contact-button {
      font-size: 14px;
    }
  }
}

.landing-for-business {
  display: flex;
  flex-direction: column;

  .headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 540px;
    margin-bottom: 30px;

    &.main {
      margin-top: 50px;
    }

    .top {
      text-align: center;
      color: $base-color;
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .bottom {
      max-width: 440px;
      text-align: center;
      font-size: 18px;
      color: $base-color;
      line-height: 24px;

      &.full-width {
        max-width: none;
      }
    }
  }

  .blocks {
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

    .block {
      background-color: red;
      display: flex;
      flex-direction: column;
      max-width: 340px;
      height: 500px;
      width: 100%;
      margin: 0px 20px;
      box-sizing: border-box;
      padding: 30px;

      .type {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
      .price {
        font-size: 24px;
        text-align: center;

        .monthly {
          text-transform: lowercase;
        }

        margin-bottom: 30px;
      }

      .benefits {
        display: flex;
        flex: 1;
        flex-direction: column;

        .benefit {
          display: flex;

          font-size: 16px;
          margin-bottom: 10px;

          &::before {
            display: flex;
            content: '';
            flex: none;
            width: 14px;
            height: 14px;
            margin-right: 10px;
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: center center;
            margin-top: 3px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      .choose-button {
        font-size: 16px;
        height: 44px;
        max-width: 220px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        font-weight: 500;

        &:hover {
          opacity: 0.9;
        }
      }

      &.free {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(244, 245, 246, 1) 100%
        );

        .type,
        .price,
        .benefits {
          color: $base-color;
        }

        .benefits {
          .benefit {
            &::before {
              background-image: url('./Assets/OkGray.svg');
            }
          }
        }

        .choose-button {
          color: #ffffff;
          background: linear-gradient(
            270deg,
            rgba(98, 122, 147, 1) 0%,
            rgba(59, 84, 100, 1) 100%
          );
        }
      }

      &.premium {
        background: linear-gradient(
          270deg,
          rgba(98, 122, 147, 1) 0%,
          rgba(59, 84, 100, 1) 100%
        );

        .type {
          color: #eddb8f;
        }

        .price,
        .benefits {
          color: #ffffff;
        }

        .benefits {
          .benefit {
            &::before {
              background-image: url('./Assets/OkWhite.svg');
            }
          }
        }

        .choose-button {
          color: $base-color;
          background-color: #ffffff;
        }
      }
    }
  }

  .help-text {
    align-self: center;
    max-width: 570px;
    font-size: 13px;
    color: $base-color;
    text-align: center;
  }

  .demo-button {
    font-size: 16px;
    height: 44px;
    max-width: 220px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-weight: 500;

    color: #ffffff;
    background: #128c7e;
    border-radius: 22px;

    > .icon {
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .contact-button {
    font-size: 18px;
    color: $base-color;
    font-weight: 500;
    text-decoration: underline;
  }
}
