@import 'styles/vars';

// #CBD1DD

.mobile #app-footer {
  .top {
    flex-direction: column;

    .links {
      .link {
        text-align: center;
      }
    }

    .social-links {
      justify-content: center;
    }
  }
}

#app-footer {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;

  .top {
    width: 100%;
    display: flex;
    min-height: 155px;
    align-items: center;
    justify-content: center;

    .links {
      flex: 1;
      display: flex;
      flex-direction: column;

      .link {
        font-size: 16px;
        margin: 3px 0px;
        color: $base-color;
        font-weight: 500;
        line-height: normal;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .social-links {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .link {
        display: flex;
        margin: 0px 10px;
        align-self: center;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.footer-love {
  font-size: 12px;
  color: $base-color;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  .heart {
    margin: 5px;
  }

  &.light {
    color: #bfc5cb;
  }
}
