.grid {
  display: flex;
}

.grid-mode-vertical {
  flex-direction: row;
}

.grid-mode-horizontal {
  flex-direction: column;
  flex: 1;
}

.grid-item {
  display: flex;
  box-sizing: border-box;
}

.grid-field {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.grid-mode-horizontal > .grid-item {
  width: 100%;
}

.grid-mode-vertical > .grid-item {
  flex: 1;
}

.grid-mode-horizontal > .grid-field {
  flex: 1;
}

.grid-mode-vertical > .grid-field {
  flex: 100%;
}
