@import 'styles/vars';

.block-info {
  > .label {
    color: #707483;
    font-size: 12px;
    line-height: normal;
  }

  > .content {
    display: flex;
    margin-top: 5px;

    .block-text {
      flex: 1;
      color: $base-color;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
    }

    .block-desc {
      flex: 1;
      color: $base-color;
      font-size: 14px;
      line-height: normal;
      word-break: break-word;
    }
  }
}
