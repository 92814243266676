@import 'styles/vars';

.mobile {
  .business-photos-slider {
    position: absolute;
    height: 100%;

    .slider-container {
      .swiper-pagination {
        bottom: 35px;

        .swiper-pagination-bullet {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}

.desktop {
  .business-photos-slider {
    width: 512px;
    height: 400px;
    overflow: hidden;

    .slider-container {
      .swiper-pagination {
        bottom: 10px;
      }
    }
  }
}

.business-photos-slider {
  display: flex;
  position: relative;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 1;

  .slider-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    .swiper-button-prev::after,
    .swiper-button-next::after {
      content: '';
    }
    .swiper-button-prev {
      transform: rotate(180deg);
    }
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 0;
      background-image: url('./Assets/Arrow.svg');
      height: 50px;
      width: 50px;
      transition: opacity 0.2s;
    }
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;
      }
    }

    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: #f5faff;
        opacity: 1;
        height: 14px;
        width: 14px;
      }

      .swiper-pagination-bullet-active {
        background-color: $base-color;
      }
    }
  }

  .photo-item {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
