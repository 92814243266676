@import 'styles/vars';

$heartbeatSize: 25px;
$heartbeatTiming: 1.2s;

$input-radius: 22px;

$explorer-input-height: 44px;

@keyframes heartbeatanim {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.explorer-search-button {
  background-color: transparent;
  border: 0px;
  width: 35px;
  height: $header-height;
  background-image: url('./Assets/SearchButton.svg');
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  box-sizing: border-box;
}

#app-header {
  .explorer-form {
    margin-left: 30px;
  }
}

.explorer-header {
  width: 100%;

  .form.location-selector-form {
    min-width: 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;

    margin-top: 0px;
    margin-left: 0px;
    height: $header-height;

    .location-selector {
      &.active {
        margin-top: 5px;
      }

      .autocomplete-container {
        width: 100%;
      }

      .location {
        background-position: 0px center;
        padding-left: 20px;
      }
    }
  }

  .form.search-input-form {
    max-width: none;
    width: auto;
    width: 100%;
    margin-top: 10px;
  }
}

.explorer-header,
#app-header.light:not(.is-not-on-top),
#app-header.light.forced {
  .explorer-search-button {
    background-image: url('./Assets/SearchButtonWhite.svg');
  }

  .form.search-input-form {
    .explorer-search-input {
      .input {
        border: 1px solid transparent;
      }
    }
  }

  .form.location-selector-form {
    .location-selector {
      .finding {
        opacity: 1;

        > .loader {
          .pulse {
            background-color: #ffffff;
          }
        }
        > .name {
          color: #ffffff;
        }
      }

      .location {
        opacity: 1;
        background-image: url('./Assets/MarkerCurrentWhite.svg');
        align-items: center;

        &::after {
          content: '';
          display: flex;
          width: 12px;
          height: 15px;
          background-image: url('./Assets/ArrowDownWhite.svg');
          background-color: transparent;
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: center;
          margin-left: 10px;
        }

        > .name {
          color: #ffffff;
        }
      }
    }
  }
}

.explorer-form {
  flex: 1;
  display: flex;

  .form.search-input-form {
    max-width: 375px;
  }

  .form.location-selector-form {
    margin-left: 30px;
    max-width: 250px;
  }
}

.form.location-selector-form {
  flex: 1;
  .location-selector {
    display: flex;
    align-items: center;
    height: $explorer-input-height;

    &.active:not(.is-loading) {
      box-shadow: $base-shadow;
      border-radius: $input-radius;
    }

    .input {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      border: 1px solid transparent;
      border-radius: $input-radius;
      padding: 12px 30px 12px 30px;
      font-size: 16px;
      font-weight: 600;
      color: $base-color;
      background-color: #ffffff;
      box-sizing: border-box;

      background-image: url('./Assets/MarkerCurrent.svg');
      background-repeat: no-repeat;
      background-position: 10px center;
      background-size: 13px;
      transition: all 0.15s ease-in;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #a7acc3;
      }
    }

    .finding {
      display: flex;
      cursor: default;
      align-items: center;

      > .loader {
        margin-right: 5px;
        width: $heartbeatSize;
        height: $heartbeatSize;

        .pulse {
          width: $heartbeatSize;
          height: $heartbeatSize;
          border-radius: 50%;
          position: absolute;
          background-color: #5472f1;
          animation: heartbeatanim $heartbeatTiming ease-in-out infinite;
          transform: scale(0);
          outline: 1px solid transparent;
          &:nth-child(2) {
            animation-delay: 0.35s;
          }
        }
      }
      > .name {
        color: #5472f1;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .location {
      max-width: 220px;
      cursor: pointer;
      display: flex;
      border: 1px solid transparent;
      background-image: url('./Assets/MarkerCurrent.svg');
      background-repeat: no-repeat;
      background-position: 10px center;
      background-size: 13px;
      padding-left: 30px;
      align-items: center;

      &::after {
        content: '';
        display: flex;
        width: 12px;
        height: 15px;
        background-image: url('./Assets/ArrowDown.svg');
        background-color: transparent;
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px;
      }

      > .name {
        color: #5472f1;
        font-weight: 500;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        opacity: 0.95;
      }
    }

    .results-container {
      .results {
        top: -20px;
        padding-top: 20px;
        padding-bottom: 10px;
        box-shadow: $base-shadow;
        border-bottom-left-radius: $input-radius;
        border-bottom-right-radius: $input-radius;
        background-color: #ffffff;
        overflow: hidden;
        border: 0px;

        .item-value {
          border: 0px;
          padding-left: 30px;

          .near-me {
            font-weight: bold;
            color: #037aff;
            display: flex;
            flex-direction: row;

            &::before {
              display: flex;
              content: '';
              width: 14px;
              height: 14px;
              background-image: url('./Assets/Location.svg');
              background-size: 14px;
              background-repeat: no-repeat no-repeat;
              color: #037aff;
              margin-left: -10px;
              margin-right: 10px;
              align-self: center;
            }
          }
        }
      }
    }
  }
}

.form.search-input-form {
  max-width: 375px;
  width: 100%;
  position: relative;

  .explorer-search-input {
    flex: 1;
    box-shadow: $base-shadow;
    border-radius: $input-radius;
    height: $explorer-input-height;

    .clear-button {
      position: absolute;
      cursor: pointer;
      z-index: 2;
      right: 0px;

      width: 44px;
      height: $explorer-input-height;
      box-sizing: border-box;

      background-image: url('./Assets/Clear.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 80%;
    }

    &.is-empty {
      .clear-button {
        display: none;
      }
    }

    &.has-items:not(.is-empty) {
      .input {
        &:focus {
          border: 1px solid transparent;
        }
      }
    }

    .input {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      border: 1px solid #a7acc3;
      border-radius: $input-radius;
      padding: 12px 30px 12px 30px;
      font-size: 16px;
      font-weight: 600;
      color: $base-color;
      background-color: #ffffff;

      background-image: url('./Assets/Search.svg');
      background-repeat: no-repeat;
      background-position: 10px center;
      background-size: 13px;
      transition: all 0.15s ease-in;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #a7acc3;
      }
    }

    .results-container {
      .results {
        max-height: 70vh;
        top: -20px;
        padding-top: 20px;
        padding-bottom: 10px;
        box-shadow: $base-shadow;
        border-bottom-left-radius: $input-radius;
        border-bottom-right-radius: $input-radius;
        background-color: #ffffff;
        overflow: hidden;
        overflow-y: scroll;

        .item-label {
          padding: 15px 10px 5px 10px;

          > .text {
            color: #747b9f;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .item-value {
          border-bottom: 0px;
          padding: 10px 10px;
          max-width: 100%;

          &:hover {
            background-color: $blue-background;
          }

          .image {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            margin-right: 10px;
            background-color: #c3c3c3;
          }

          .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            flex: 1;
            min-width: 0;

            .name {
              color: $base-color;
              font-weight: 500;

              font-size: 16px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
            .desc {
              color: $gray-color;

              font-size: 12px;
              font-weight: normal;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

.mobile {
  .explorer-list-header {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 15px;

    .no-results {
      margin-top: 20px;
    }
  }
}
.explorer-list-header {
  display: flex;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 20px;

  .no-results {
    flex: 1;
    display: flex;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
}
