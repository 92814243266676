@import 'styles/vars';

.business-reserve-button {
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    max-width: 300px;
    align-items: center;
    flex-direction: column;

    .services {
      display: flex;
      align-items: center;
      height: 50px;
      box-sizing: border-box;
      font-weight: normal;
      font-size: 18px;
      color: #3e3e3e;
      color: $gray-color;
      width: 100%;
      margin-bottom: 5px;
    }
    .btn {
      height: 50px;
      width: 100%;
    }
  }
}
