@import 'components/Common/Button/Button';

.desktop {
  .business-types-list {
    grid-template-columns: repeat(auto-fill, 165px);
    grid-gap: 20px;

    .business-type-item {
      .image {
        width: 165px;
      }
      .name {
        font-size: 15px;
      }
    }
  }
}
.business-types-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  justify-content: space-evenly;
  grid-gap: 6px;

  .business-type-item {
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: rgb(16 25 40 / 6%) 0px 7px 16px 0px;
    transition: border 0.15s;

    .image {
      width: 100%;
      height: 100px;
      background-color: #979797;
      object-fit: cover;
    }
    .name {
      color: #000000;
      text-align: center;
      font-size: 13px;
      box-sizing: border-box;
      padding: 7px 3px;
      word-break: break-word;
      width: 100%;
      transition: background 0.15s;
    }

    &.selectable {
      border: 1px solid transparent;
      cursor: pointer;

      &:not(.selected) {
        opacity: 0.6;
      }

      &.selected {
        border: 1px solid #3467f9;

        .name {
          color: #ffffff;
          background: $btn-blue-gradient;
          font-weight: bold;
        }
      }
    }

    &:hover {
      box-shadow: rgb(16 25 40 / 8%) 0px 16px 32px 0px;
    }
  }
}
