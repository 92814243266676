.desktop {
  .businesses-list {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, 450px);
      grid-gap: 30px;
      justify-content: space-around;
    }
    .business-item {
      .image {
        height: 210px;
      }
    }
  }
}
.businesses-list {
  .grid-container {
    display: flex;
    width: 100%;
    grid-gap: 20px;
  }

  .business-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: rgb(16 25 40 / 6%) 0px 7px 16px 0px;

    .image {
      display: flex;
      width: 100%;
      height: 195px;
      background-color: #979797;

      .source {
        max-width: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .info {
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      padding: 10px 10px;

      .top {
        display: flex;
        align-items: flex-start;

        .name {
          flex: 1;
          color: #000000;
          font-size: 18px;
          box-sizing: border-box;
          word-break: break-word;
          font-weight: 500;
        }
      }
      .address {
        color: #000000;
        font-size: 12px;
        margin-top: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      box-shadow: rgb(16 25 40 / 8%) 0px 16px 32px 0px;
    }
  }
}
