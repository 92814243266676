.booking-top-bar {
  .title-content {
    display: flex;
    flex-direction: column;

    .step {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 20px;
    }
    .title {
    }
  }
}
