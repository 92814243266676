@import 'styles/vars';
@import '../Staff.scss';

.desktop {
  .staff-categories {
    .categories-container {
      padding-left: $staff-base-padding;
      padding-right: $staff-base-padding;
    }
  }
}
.staff-categories {
  padding-top: $base-padding;

  .page-title {
    padding-top: 0px;
  }
  .categories-cta {
    display: flex;
    flex-direction: column;
    padding: 30px 0px;
    align-items: center;
    justify-content: center;

    .no-services {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $base-color;
      margin-bottom: 30px;
      font-weight: bold;
      font-size: 20px;

      .subtitle {
        margin-top: 20px;
        color: $gray-color;
        font-weight: normal;
        font-size: 14px;
      }
    }

    .btn {
      text-transform: none;
      min-width: 200px;
      font-size: 18px;
    }
  }

  .categories-container {
  }
  .category {
    cursor: default;
    margin-bottom: 20px;
    background-color: $blue-background;

    > .info {
      padding: $base-padding;
      align-items: center;

      .icon-grab {
        cursor: grab;
        padding-right: $base-padding;
      }

      .content {
        .name {
          cursor: grab;
          color: #3d6bef;
          font-size: 22px;
          font-weight: bolder;
        }
      }
    }

    .services {
      padding-left: $base-padding;
      padding-right: $base-padding;

      .service {
        background-color: $blue-background;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e4eaf2;

        .icon-grab {
          cursor: grab;
          padding-right: $base-padding;
        }

        .content {
        }

        &:last-child {
          border-bottom: 0px;
        }

        .top {
          .name {
            color: $base-color;
            font-size: 18px;
            font-weight: bold;
          }
          .price {
            color: $base-color;
            font-size: 18px;
          }
        }
        .minutes {
          color: $base-color;
          font-size: 14px;
        }
      }
    }
  }
}
