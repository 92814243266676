.desktop {
  .business-landing-page {
    .ratings-info {
      &:not(.inline) {
        .star {
          height: 22px;
          width: 22px;
        }
        .score,
        .count {
          font-size: 22px;
        }
        .score {
          margin-left: 3px;
        }
      }
    }
  }
}
.ratings-info {
  display: flex;
  align-items: center;
  .star {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    position: relative;
    margin-top: -3px;
  }
  .score {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-right: 4px;
  }
  .count {
    color: #000000;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
  }

  &.business-page {
    margin-top: 10px;

    .star {
      height: 15px;
      width: 15px;
    }
    .score,
    .count {
      font-size: 16px;
    }

    &.inline {
      margin-top: 7px;

      .star {
        height: 13px;
        width: 13px;
      }
      .score,
      .count {
        font-size: 12px;
      }
    }
  }
}
