@mixin renderIcon($name, $height, $width: false) {
  background-image: url('./Assets/#{$name}.svg');
  border-radius: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: $height;

  @if ($width) {
    width: $width;
  } @else {
    width: $height;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    background-color: #c3c7ce;
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }

  &.icon-plus::after {
    @include renderIcon('plus', 16px);
  }

  &.icon-options::after {
    @include renderIcon('options', 20px);
    background-size: 20px 20px;
    background-position: center center;
    padding: 3px;
  }

  &.icon-grab::after {
    @include renderIcon('grab', 12px);
    background-size: contain;
  }
}
