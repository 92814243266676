@import 'styles/vars';

.mobile {
  .staff-complete-options {
    .complete-option {
      min-height: 70px;

      .label {
        font-size: 16px;
      }

      .status {
        max-width: 100px;

        .completed {
          width: 35px;
          height: 35px;
        }

        .btn {
          height: 35px;
        }
      }
    }
  }
}
.staff-complete-options {
  display: flex;
  flex-direction: column;

  .activate-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      max-width: 200px;
      border-radius: 44px;
    }
  }

  .complete-option {
    display: flex;
    box-shadow: $base-shadow;
    border-radius: 10px;
    padding: 0px 20px;
    min-height: 100px;
    box-sizing: border-box;

    margin-bottom: 20px;
    align-items: center;

    &:hover {
      box-shadow: $hover-base-shadow;
    }

    &.completed {
      .label {
        color: $gray-color;
      }
    }

    .label {
      flex: 1;
      color: $base-color;
      font-weight: 500;
      font-size: 20px;
    }

    .status {
      display: flex;
      flex: 1;
      max-width: 200px;
      justify-content: center;

      .completed {
        width: 40px;
        height: 40px;
      }

      .btn {
        text-transform: none;
        height: 40px;
        width: 100%;
        min-width: auto;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
