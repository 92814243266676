@import 'styles/vars';

$hour-height: 40px;

.hours-selector {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;

  .day {
    display: flex;
    margin-bottom: 25px;

    .left {
      display: flex;
      align-items: center;
      align-self: flex-start;
      flex: none;
      width: 110px;
      min-height: $hour-height;

      .checkbox {
      }
      .name {
        color: $base-color;
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
      }
    }

    .hours {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;

      .disabled {
        display: flex;
        color: $gray-color;
        font-size: 14px;
        align-self: center;
        margin-right: $hour-height;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
      .hour {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }
        .time {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          overflow: hidden;
          cursor: pointer;

          height: $hour-height;

          outline: none;
          border: 0px;
          width: 90px;
          background-color: $blue-background;
          display: flex;
          font-size: 14px;
          color: $base-color;
          text-align: center;

          &.start {
          }
          &.end {
          }
        }

        .dash-separator {
          color: $base-color;
          font-size: 14px;
          font-weight: 500;
          margin: 0px 8px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button {
          height: $hour-height;
          width: $hour-height;
          cursor: pointer;
          display: flex;
          padding: 5px;
          border: 0px;
          background-color: transparent;
          align-items: center;
          justify-content: center;

          &.inactive {
            opacity: 0.3;
          }

          .icon {
          }
        }
      }
    }
  }
}
