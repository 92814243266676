@import 'styles/vars';

$side-bar-width: 250px;

$menu-button-padding: 10px;

.desktop {
  .staff-panel {
    .page.base-size {
      max-width: $page-max-width - $side-bar-width;
    }
  }
}

.staff-menu-container-shadow {
  height: calc(100vh - $header-height);
  top: $header-height;
  position: sticky;
  max-height: calc(100vh - $header-height);
  width: 250px;
  overflow-y: scroll;
}
.staff-menu-container {
  min-height: calc(100vh - $header-height);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 250px;
  //flex: none;

  .statuses {
    display: flex;
    align-items: center;

    .status-link {
      margin-right: 15px;
    }
    .membership-link {
      margin-left: 15px;
    }
  }

  .name {
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }

  .image {
    border-radius: 10px;
    overflow: hidden;
    width: 104px;
    height: 104px;
    display: flex;
    box-sizing: border-box;
    background: #c3c3c3;
    border: 2px solid #262c48;
    transition: border 0.15s ease-in;

    &:hover {
      border-color: #ffffff;
    }

    .source {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .header-label {
    font-weight: 500;
    font-size: 14px;
    color: #555f8b;
    display: flex;
    padding: 0px $menu-button-padding;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .menu-button {
    width: 100%;
    flex: none;
    display: flex;
    font-size: 16px;
    color: #ffffff;
    min-height: 44px;
    padding: $menu-button-padding;
    align-items: center;
    box-sizing: border-box;

    border-top: 1px solid #2d3453;

    &.marked {
      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: #fc4e07;
        margin-left: 10px;
        border-radius: 100%;
      }
    }

    &:first-child,
    &.no-border {
      border-top: 0px;
    }

    &.active {
      font-weight: 500;
    }

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      margin-right: 15px;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &.calendar::before {
      background-image: url('./Assets/Calendar.svg');
    }

    &.appointments::before {
      background-image: url('./Assets/Appointments.svg');
    }

    &.clients::before {
      background-image: url('./Assets/Clients.svg');
    }

    &.services::before {
      background-image: url('./Assets/Services.svg');
    }

    &.staff::before {
      background-image: url('./Assets/Staff.svg');
    }

    &.share::before {
      background-image: url('./Assets/Share.svg');
    }

    &.settings::before {
      background-image: url('./Assets/Settings.svg');
    }

    &:hover,
    &.active {
      background-color: #4a5274;
      color: #ffffff;

      &.calendar::before {
        background-image: url('./Assets/CalendarActive.svg');
      }

      &.appointments::before {
        background-image: url('./Assets/AppointmentsActive.svg');
      }

      &.clients::before {
        background-image: url('./Assets/ClientsActive.svg');
      }

      &.services::before {
        background-image: url('./Assets/ServicesActive.svg');
      }

      &.staff::before {
        background-image: url('./Assets/StaffActive.svg');
      }

      &.share::before {
        background-image: url('./Assets/ShareActive.svg');
      }

      &.settings::before {
        background-image: url('./Assets/SettingsActive.svg');
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;
  }
}
