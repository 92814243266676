@import 'styles/vars';

@keyframes notification-in {
  0% {
    top: -30px;
    opacity: 0;
  }
  70% {
    top: 5px;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

.mobile {
  .notifications {
    .notification {
      max-width: 300px;
      padding: 8px $base-padding;
    }
  }
}

.notifications {
  display: flex;
  position: fixed;
  flex-direction: column;
  min-width: 400px;
  top: $header-height;
  align-items: center;
  left: calc(50% - 200px);

  .notification {
    width: 100%;
    max-width: 400px;
    position: relative;
    margin-bottom: $base-padding;
    box-sizing: border-box;
    padding: 10px $base-padding;
    border-radius: 35px;
    font-weight: 500;
    font-size: 16px;
    word-break: break-word;
    animation: 0.25s ease-in 0s 1 notification-in;
    transition: all 0.15s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;

    top: 0px;

    &.hiding {
      top: 20px;
      opacity: 0;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    &.success {
      color: #ffffff;
      background: linear-gradient(
        90deg,
        rgba(41, 178, 146, 1) 0%,
        rgba(62, 219, 165, 1) 100%
      );
      //box-shadow: 0px 0px 30px 1px #3eb8c2;
    }
    &.error {
      color: #ffffff;
      background: linear-gradient(
        90deg,
        rgba(218, 35, 70, 1) 0%,
        rgba(246, 118, 143, 1) 100%
      );
      //box-shadow: 0px 0px 30px 1px #e74968;
    }
  }

  z-index: 10000;
}
