@import 'styles/vars';

.membership-invoices-title {
  color: $base-color;
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
}

.membership-invoices-list {
  width: 100%;
  .membership-invoice-item {
    display: flex;
    width: 100%;
    box-shadow: $base-shadow;
    border-radius: 10px;
    padding: $base-padding 20px;
    margin-bottom: 20px;

    .card {
      color: $base-color;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .date {
      flex: 1;
      color: $base-color;
      font-size: 13px;
      text-align: center;
    }
    .price {
      color: $base-color;
      font-size: 13px;
      font-weight: bold;
    }
  }
}
