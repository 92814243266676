@import 'styles/vars';

@keyframes dialog-animation-mobile-up {
  from {
    bottom: -40px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes dialog-animation-mobile-down {
  from {
    bottom: 0px;
  }
  to {
    bottom: -40px;
  }
}

@keyframes dialog-animation-desktop-down {
  from {
    top: 0vh;
  }
  to {
    top: 30vh;
  }
}

@keyframes dialog-animation-desktop-up {
  from {
    top: 30vh;
  }
  to {
    top: 0vh;
  }
}

.mobile {
  .modal.closing {
    .dialog {
      bottom: -40px;
      animation: 0.15s ease-in 0s 1 dialog-animation-mobile-down !important;
    }
  }
  .dialog {
    bottom: 0px;
    left: 0px;
    top: auto !important;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    animation: 0.15s ease-in 0s 1 dialog-animation-mobile-up;

    .bottom {
      max-width: 290px;
    }
  }
}

.modal.closing {
  .dialog {
    top: 0vh;
    animation: 0.2s ease-in 0s 1 dialog-animation-desktop-up;
  }
}

.dialog {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  flex-direction: column;
  width: 100%;
  top: 30vh;
  left: calc((100vw - 500px) / 2);
  max-width: 500px;
  animation: 0.2s ease-in 0s 1 dialog-animation-desktop-down;

  .header {
    display: flex;
    margin-top: 4px;
    height: 45px;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    .close-button {
      user-select: none;
      cursor: pointer;
      padding: 0px;
      padding-left: 15px;
      color: $base-color;
      font-size: 17px;
      font-weight: 500;
      height: 100%;
      background-color: transparent;
      border: 0px;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    max-width: 360px;
    padding-bottom: 40px;

    .titles {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .title {
        text-align: center;
        color: $base-color;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
      }
      .subtitle {
        text-align: center;
        color: $base-color;
        font-size: 20px;
      }
    }

    .content {
      color: $base-color;
      font-size: 18px;

      .default-actions {
        display: flex;
      }
    }
  }
}
