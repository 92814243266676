@import 'styles/vars';

.mobile {
  .info-box {
    margin-bottom: 20px;

    &.info {
      padding: 10px 15px 10px 36px;

      &::before {
        top: 10px;
        left: 10px;
        width: 18px;
        height: 18px;
        background-size: 18px;
      }
    }
  }
}

.info-box {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-size: 14px;
  color: $gray-color;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: $blue-background;
  line-height: 20px;
  align-items: center;

  &.no-margin {
    margin-bottom: 0px;
  }

  &.info {
    color: $base-color;
    background-color: #edf0ff;
    padding: 12px 20px 12px 58px;

    &::before {
      position: absolute;
      content: '';
      display: flex;
      left: 20px;
      top: 9px;
      width: 24px;
      height: 24px;
      background-image: url('./Assets/Info.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 24px;
    }

    .link {
      color: $base-color;
      text-decoration: underline;

      &:hover {
        opacity: 0.85;
      }
    }
  }
}
