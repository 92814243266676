@import 'styles/vars';

.business-open-addres-link {
  display: flex;
  align-self: flex-start;
  font-size: 14px;
  color: $base-color;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    opacity: 0.85;
  }
}
