.custom-check-box {
  cursor: pointer;
  background-color: #ffffff;
  width: 24px;
  height: 24px;
  border: 1px solid #747b9f;
  position: relative;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  overflow: hidden;

  &:hover {
    opacity: 0.9;
  }
  &.active {
    border: 0;
    background: linear-gradient(
      90deg,
      rgba(52, 103, 249, 1) 0%,
      rgba(118, 153, 255, 1) 100%
    );

    &::after {
      position: absolute;
      content: '';
      width: 18px;
      height: 16px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('./Assets/Check.svg');
      top: 0px;
      left: 8px;
    }
  }
}
