@import 'styles/vars';

@keyframes appointment-status-in {
  0% {
    top: -20px;
    opacity: 0;
    transform: scale(0.7);
  }
  70% {
    top: 5px;
    transform: scale(1.2);
  }
  100% {
    top: 0px;
    opacity: 1;
    transform: scale(1);
  }
}

.mobile {
  .appointment-status {
    &.tag {
      height: 25px;

      .status-label {
        font-size: 12px;
      }
    }
  }
}

.appointment-status {
  display: flex;
  position: relative;

  /* .status-label {
  } */
  .status-icon {
    margin-left: 15px;
    position: relative;
  }

  &.tag {
    height: 26px;
    width: 100px;
    background-color: #717171;
    border-radius: 26px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .status-label {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .status-icon {
      display: flex;
      margin-left: 5px;
      width: 15px;
      height: 15px;
    }

    &.pending {
      background: $orange-gradient;
    }
    &.confirmed {
      background: $navy-blue-gradient;
    }
    &.cancelled {
      background: $dark-gray-gradient;
    }
    &.completed {
      background: $blue-gradient;
    }
  }
}

.appointment-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  &.confirmed {
    .status-icon {
      top: 0px;
      opacity: 1;
      animation: 0.3s ease-in 0s 1 appointment-status-in;
    }
  }

  .text {
    margin-right: 15px;
  }
}

.appointment-services-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  box-sizing: border-box;

  .service-item {
    display: flex;
    box-sizing: border-box;
    display: flex;
    padding-bottom: 11px;
    padding-top: 11px;

    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
    }

    .check {
      margin-top: 14px;
    }
    .content {
      flex: 1;
      padding-left: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .top {
        display: flex;

        .name {
          flex: 1;
          word-break: break-word;
          font-size: 18px;
          font-weight: bold;
          color: $base-color-secondary;
          padding-right: 10px;
        }
        .price {
          font-size: 18px;
          color: $base-color-secondary;
        }
      }
      .time {
        font-size: 14px;
        color: #6f7283;
      }
      .notes {
        margin-top: 10px;
        word-break: break-word;
        font-size: 12px;
        color: $base-color-secondary;
      }
    }
  }

  > .total {
    display: flex;
    align-self: flex-end;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #e4eaf2;

    .label {
      color: #6f7283;
      font-size: 14px;
      padding-right: 20px;
    }
    .amount {
      font-weight: 500;
      font-size: 18px;
      color: $base-color-secondary;
    }
  }
}

.appointment-rating-items {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-direction: row-reverse;

  .star {
    cursor: pointer;

    height: 50px;
    width: 65px;

    background-image: url('../Review/Assets/StarGray.svg');
    background-size: 44px 44px;
    background-position: center center;
    background-repeat: no-repeat;

    transition: transform 0.1s;

    &.active,
    &.active ~ .star,
    &:hover,
    &:hover ~ .star {
      background-image: url('../Review/Assets/Star.svg');
    }

    &:hover {
      transform: scale(1.15);
    }
  }
}
