@import 'styles/vars';
@import '../DatesList/DatesList';
@import '../../Tabbar/Tabbar';

$hour-height: 55px;
$hour-width: 62px;
$hour-right-padding: 5px;

$hours-line-color: #e0e0e4;

$item-opacity: 0.7;

@keyframes calendar-dates-in {
  from {
    opacity: 0;
  }
  to {
    opacity: $item-opacity;
  }
}

@mixin time-column {
  .time-column {
    user-select: none;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: $hour-width;
    flex: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    border-right: 1px solid $hours-line-color;
    background-color: #ffffff;

    .hour {
      color: #747b9f;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      height: $hour-height;
      border-top: 1px solid $hours-line-color;
      box-sizing: border-box;
      padding-top: 2px;
      padding-right: $hour-right-padding;
      line-height: normal;

      &::before {
        content: '';
        position: absolute;
        width: 100vw;
        left: 0px;
        top: -1px;
        height: 1px;
        background-color: $hours-line-color;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100vw;
        left: $hour-width;
        top: calc(50% - 1px);
        height: 1px;
        border-bottom: 1px solid rgba($hours-line-color, 0.4);
      }
      &:last-child {
        border-bottom: 1px solid $hours-line-color;
      }

      .hour-text {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .now-time {
    //scroll-margin-top: calc(20px + $header-height + $dates-list-height);
    //scroll-margin-top: 20px;

    position: absolute;
    display: flex;
    align-items: center;
    height: 19px;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
    width: 100%;
    z-index: 4;

    .time {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      font-weight: 500;
      padding: 0px 6px;
      font-size: 11px;
      color: #ffffff;
      z-index: 2;
      line-height: normal;
      word-break: keep-all;
      white-space: nowrap;
      width: $hour-width;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        0deg,
        rgba(73, 95, 219, 1) 0%,
        rgba(105, 145, 255, 1) 100%
      );
    }

    &::before {
      content: '';
      position: absolute;
      width: 100vw;
      left: 14px;
      top: 8.5px;
      height: 3px;
      background-color: #668cfb;
      z-index: 1;
      opacity: 0.9;
    }
  }
}

@mixin appointment-day {
  .appointments-day {
    display: flex;
    flex: 1;
    z-index: 1;

    .appointment-item {
      user-select: none;
      animation: 0.15s ease-in 0s 1 calendar-dates-in;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      transition: all 0.4s ease-in;
      min-height: 37px;
      padding-right: 1px;

      /*flex: 1;
    margin-left: 5px;

    &:last-child {
      margin-right: 5px;
    }*/

      flex: none;
      position: absolute;
      left: 0px;

      &.past .content {
        background-color: #c2c9db;
        opacity: 0.9;

        &:hover {
          opacity: 1;
          background-color: #c2c9db;
        }
      }

      &.completed .content {
        background-color: #c2c9db !important;
        opacity: 0.9;

        &:hover {
          opacity: 1;
          background-color: #c2c9db !important;
        }
      }

      &.current .content {
        background-color: #5eeadf;
        opacity: 0.9;

        &:hover {
          opacity: 1;
        }
      }

      &.future .content {
        background-color: #c9e9eb;
        opacity: 0.9;

        &:hover {
          opacity: 1;
        }
      }

      .content {
        color: $base-color;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 5px;
        display: flex;
        padding: 3px 5px;
        height: 100%;
        width: 100%;
        flex-direction: column;

        .time {
          color: $base-color;
          display: flex;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          width: 100%;
        }
        .label {
          color: $base-color;
          display: flex;
          justify-content: center;
          font-size: 12px;
          font-weight: 500;
          width: 100%;
          font-weight: bold;
          line-height: normal;
        }
      }
    }
  }
}

@mixin calendar-day-background {
  display: flex;

  background-image: linear-gradient(
    45deg,
    #b5b5b5 10%,
    #f5faff 10%,
    #f5faff 50%,
    #b5b5b5 50%,
    #b5b5b5 60%,
    #f5faff 60%,
    #f5faff 100%
  );
  background-size: 7.07px 7.07px;

  .time-range {
    position: absolute;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    left: 0px;
    z-index: 1;
  }
}

@mixin new-appointment {
  .new-appointment {
    position: absolute;
    top: 0px;
    left: -$hour-width;
    width: calc(100% + $hour-width);
    height: 100%;

    .marker {
      cursor: crosshair;
      display: flex;
      background-color: #c9e9eb;
      width: 100%;
      position: absolute;
      left: 0px;

      .label {
        display: flex;
        font-size: 13px;
        color: $base-color;
        width: $hour-width;
        justify-content: flex-end;
        box-sizing: border-box;
        font-weight: 500;
        padding-right: $hour-right-padding;
      }
    }
  }
}

@mixin appointments-container {
  .appointments-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
    z-index: 3;

    @include appointment-day;
    @include new-appointment;
  }
}

.staff-calendar {
  .calendar-day {
    background-color: #f2f4fa;
    flex: 1;
    position: relative;
    display: flex;
    overflow: hidden;

    @include time-column;
    @include calendar-day-background;

    @include appointments-container;
  }
}
