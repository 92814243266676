.business-features {
  display: flex;
  flex-direction: column;

  .feature:last-child {
    margin-bottom: 0px;
  }
  .feature {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .icon {
      margin-right: 10px;
    }
    .name {
      font-size: 16px;

      &.status {
        &.closed {
          color: #ff3535;
        }
        &.open {
          color: #1fd0a0;
        }
      }
    }
  }
}
