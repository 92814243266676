.appointment-actions {
  display: flex;
  flex-direction: column;

  width: 100%;
  align-items: center;

  .btn {
    width: 100%;
    max-width: 250px;
  }
}
