@import 'styles/vars';

.membership-type {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex: none;
  align-self: center;

  height: 15px;
  padding: 0px 5px;

  &.large {
    height: 32px;
    padding: 0px 10px;
    background-color: $base-color;

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    .type {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      height: 20px;
      flex: none;
      text-transform: uppercase;
    }
  }

  .icon {
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
  .type {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex: none;
    text-transform: uppercase;
    font-weight: 500;
  }

  &.free {
    color: #f5faff;
  }
  &.premium {
    color: #eddb8f;
    font-weight: 600;
  }
}
