@import 'styles/vars';

.text {
  display: inline;

  &.content-title {
    color: $base-color;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    line-height: normal;
  }
}
