@import 'styles/vars';

.booking-page {
  padding-bottom: 100px;
}

.booking-page-container {
  justify-content: center;
  display: flex;
  width: 100%;

  .left-content {
    width: 100%;
  }
}

.desktop {
  .booking-page-container {
    justify-content: center;
    display: flex;
    width: 100%;

    .left-content {
      width: 620px;
    }

    .booking-checkout-details {
      background: $block-gradient;
      margin-left: 20px;
      flex: 1;
    }

    .booking-confirm-page {
      background: $block-gradient;
    }

    .label-notes {
      font-size: 16px;
    }
    .input-notes {
      background-color: #f5faff;
    }
  }
}
