@import 'styles/vars';

.mobile {
  .membership-next-payment {
    font-size: 16px;
  }

  .membership-info {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
    height: auto;

    .price-container {
      align-items: center;

      .type {
        margin-bottom: 5px;
        font-size: 28px;
      }
      .price {
        font-size: 18px;
      }
    }

    .btn {
      margin-top: 20px;
      min-width: 240px;
    }
  }

  .card-on-file {
    min-width: auto;

    .info {
      font-size: 16px;
    }
    .change-button {
      font-size: 16px;
    }
  }
}

.membership-next-payment {
  display: flex;
  align-items: center;
  color: $base-color;
  font-size: 18px;

  .label {
    font-style: normal;
  }
  .date {
    font-weight: 500;
  }
}

.membership-info {
  display: flex;
  height: 120px;
  padding: 0px 30px;
  box-sizing: border-box;
  margin-bottom: 30px;

  &.free {
    color: $base-color;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(244, 245, 246, 1) 100%
    );
  }

  &.premium {
    color: #ffffff;
    background: linear-gradient(
      270deg,
      rgba(98, 122, 147, 1) 0%,
      rgba(59, 84, 100, 1) 100%
    );
  }

  .btn {
    align-self: center;
    max-width: 220px;
    padding: 0px;

    .text {
      font-size: 16px;
      font-weight: 500;
      text-transform: none;
    }
  }

  .price-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .type {
      font-size: 32px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .price {
      display: flex;
      align-items: center;
      font-size: 24px;

      /* .amount {
      } */
      .monthly {
        margin-left: 5px;
      }
    }
  }
}

.card-on-file {
  display: flex;
  background-color: #f5faff;
  padding: 10px;
  height: 50px;
  padding: 0px 12px;
  align-items: center;
  min-width: 335px;

  .info {
    flex: 1;
    display: flex;
    color: $base-color;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    flex: 1;
  }

  .change-button {
    cursor: pointer;
    color: $base-color;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    border: 0px;

    &:hover {
      opacity: 0.85;
    }
    .text {
      text-decoration: underline;
    }
  }
}

.membership-payment-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
