.user-photo {
  background-color: #1d2650;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  &.size-small {
    font-size: 15px;
  }
  &.size-medium {
    font-size: 30px;
  }
  &.size-large {
    font-size: 50px;
  }
}
