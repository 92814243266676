@import 'styles/vars';

.desktop {
  .business-landing-page {
    .business-details {
      &:not(.inline) {
        .container {
          .name {
            font-size: 37px;
          }
          .ratings-info {
            margin-top: 20px;
          }
          .description,
          .ratings-info {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
.business-details {
  display: flex;
  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    .name {
      color: $base-color-secondary;
      font-size: 22px;
      font-weight: bold;
      word-break: break-word;
    }

    .type {
      color: $gray-color;
      font-size: 18px;
      margin-top: 5px;
    }

    .address {
      color: $gray-color;
      font-size: 15px;
      word-break: break-word;
      margin-top: 10px;
      line-height: 20px;
    }

    .description {
      color: $base-color;
      word-break: break-word;
      font-size: 15px;
      margin-top: 10px;
    }
  }
  .image {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #979797;
    margin-left: 15px;
    overflow: hidden;
    //z-index: 2;

    .source {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &.inline {
    .name {
      font-size: 16px;
    }
    .type {
      font-size: 14px;
      margin-top: 2px;
    }
    .address {
      font-size: 10px;
      margin-top: 7px;
      line-height: 15px;
    }
  }
}
