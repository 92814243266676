@import 'styles/vars';
@import 'components/Common/Loading/Loading';

@mixin loading($color: $base-color-secondary) {
  &.working {
    .text {
      display: none;
    }
  }

  .loading {
    .circle {
      @include loadingColor($color);
    }
  }
}

$btn-gray-gradient: $dark-gray-gradient;

$btn-blue-gradient: linear-gradient(
  90deg,
  rgba(52, 103, 249, 1) 0%,
  rgba(118, 153, 255, 1) 100%
);

$btn-dark-blue-gradient: linear-gradient(90deg, #3467f9, #3463ec);

@mixin btn($fixed-height: true) {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  background-color: transparent;
  box-sizing: border-box;
  flex: auto;
  padding: 10px 15px 10px 15px;

  @if ($fixed-height) {
    height: 44px;
  } @else {
    min-height: 44px;
  }

  min-width: 120px;
  border-radius: 0px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 0px;
  font-size: 14px;

  transition: all 0.25s ease-out;

  &:focus {
    outline: none;
  }
  &:hover,
  &:active {
    opacity: 0.9;
  }

  .text {
    font-weight: 500;
  }
}

@mixin btn-transparent {
  @include loading();

  display: flex;
  border: 0px;
  color: #707483;
}

@mixin btn-normal {
  @include loading();

  display: flex;
  background-color: #ffffff;
  color: $base-color;

  &:not(.no-border) {
    border: 1px solid #3a3646;
  }

  &:hover {
    box-shadow: 0 4px 6px 0 rgb(16 25 40 / 10%);
  }
}

@mixin btn-primary {
  @include loading(#ffffff);

  border: 0px;
  color: #ffffff;
  background: $btn-gray-gradient;
}

@mixin btn-blue {
  @include loading(#ffffff);

  border: 0px;
  color: #ffffff;
  background: $btn-blue-gradient;
}

@mixin btn-dark-blue {
  @include loading(#ffffff);

  border: 0px;
  color: #ffffff;
  background: $btn-dark-blue-gradient;
}

@mixin btn-remove {
  @include loading(#ffffff);

  border: 0px;
  color: #ffffff;
  background-color: #da2346;
}

@mixin btn-gray {
  color: $gray-color;
}

@mixin btn-rounded {
  border-radius: 44px;
}

.btn {
  @include btn;

  &.normal {
    @include btn-normal;
  }

  &.primary {
    @include btn-primary;
  }

  &.blue {
    @include btn-blue;
  }

  &.dark-blue {
    @include btn-dark-blue;
  }

  &.remove {
    @include btn-remove;
  }

  &.gray {
    @include btn-gray;
  }

  &.rounded {
    @include btn-rounded;
  }
}
