@import 'styles/vars';

.mobile {
  .photos-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-gap: 20px;
    justify-content: space-around;

    .photo {
      margin: 0px;
    }
  }
}
.photos-selector {
  display: flex;
  flex-wrap: wrap;

  &.dragging {
    /* display: flex;
    flex-direction: column;
    grid-gap: 0px;
    width: 100%;
    align-items: center;

    .photo {
      margin-bottom: 20px;
    }*/
  }
  .photo {
    margin: 12px 9px;
    user-select: none;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid $base-color;
    box-sizing: border-box;
    max-width: 100%;
    height: 150px;
    width: 160px;

    .image {
      background-color: #c3c3c3;
      cursor: grab;
      width: 160px;
      height: 100px;
      object-fit: cover;
      overflow: hidden;
    }

    .bottom {
      display: flex;
      justify-content: center;
      background-color: $base-color;
      flex: 1;

      .remove {
        cursor: pointer;
        background-color: transparent;
        border: 0px;
        padding: 17px 17px;
        margin: 8px 0px;

        background-image: url('./Assets/Remove.svg');
        background-size: 15px;
        background-repeat: no-repeat;
        background-position: center center;

        &:hover {
          background-image: url('./Assets/RemoveActive.svg');
        }
      }
    }

    &.add-button {
      border: 1px solid $gray-color;
      background-color: $blue-background;
      justify-content: center;
      position: relative;

      height: 150px;
      width: 160px;

      .loading {
        justify-content: center;
      }

      .add {
        cursor: pointer;
        background-color: transparent;
        border: 0px;
        height: 100%;
        width: 100%;

        background-image: url('./Assets/Plus.svg');
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center center;

        &:hover {
          background-image: url('./Assets/PlusActive.svg');
        }
      }

      #photo-selector {
        display: none;
      }
    }
  }
}
