@import 'styles/vars';

.login-providers {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 360px;

  .login-with {
    font-size: 16px;
    color: $gray-color;
    margin-bottom: 20px;
    text-align: center;
  }

  .options {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;

    .login-button {
      cursor: pointer;
      display: flex;
      box-sizing: border-box;
      height: 44px;
      border: 0px;
      padding: 6px;
      border-radius: 44px;
      background-color: #ffffff;
      align-items: center;
      max-width: 250px;
      width: 100%;

      box-shadow: 0 0px 6px 0 rgb(16 25 40 / 10%);

      &:hover {
        box-shadow: 0 0px 6px 0 rgb(16 25 40 / 50%);
      }

      .text {
        font-size: 16px;
        font-weight: 500;
        margin-left: 10px;
      }

      /* .icon {
      } */

      &.google {
        background-color: #f14336;

        color: #ffffff;
      }

      &.apple {
        background-color: #333333;

        color: #ffffff;
      }

      &.phone {
        background-color: #ffffff;
        color: $base-color;
      }
    }
  }
  //.options
}
