@import 'styles/vars';
@import 'components/Common/Button/Button';
@import 'pages/Businesses/Business/Staff/Staff';

.table-data {
  .grid-item {
    &.header {
      background-color: $base-color-secondary !important;
      padding: 15px 0px !important;
      color: #ffffff;
      margin-bottom: 10px;
    }

    &:not(.header) {
      padding: 0px !important;
      color: $base-color-secondary;
    }

    &:not(.header):nth-child(odd) {
      background-color: #ecf2f5 !important;
    }

    &:not(.header):nth-child(even) {
      background-color: transparent !important;
    }

    > a {
      color: $base-color;

      &:hover {
        color: #ffffff;
        background-color: $base-color-secondary !important;
      }
    }

    .grid-field {
      padding-right: $base-padding !important;
      padding-left: $base-padding !important;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(.header) .grid-field {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      max-width: 100%;
    }

    .grid-field.id {
      width: 20px !important;
    }
  }

  .badge {
    background-color: $base-color;
    color: #ffffff;
    font-size: 12px;
    font-weight: normal;
    padding: 2px 4px;
    border-radius: 5px;
    line-height: normal;
  }
}
