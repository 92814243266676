@import 'styles/vars';
@import 'components/Common/Button/Button';

.desktop {
  .booking-times-list {
    .dates-list-container {
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: transparent;
      }

      &:hover ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $gray-color !important;
      }

      .dates-list {
        margin-bottom: 10px;
      }

      .date-month {
        .month {
          left: 0px;
        }

        &:first-child .date-day:first-child {
          margin-left: 0px;
        }

        &:last-child .date-day:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.modal {
  .dates-list-container {
    top: 0px;
  }
}

.desktop {
  .dates-list-container .arrow {
    &.left {
      margin-right: $base-padding;
    }
    &.right {
      margin-left: $base-padding;
    }
  }
}

.dates-list-container {
  position: sticky;
  top: $header-height;
  background: #ffffff;
  display: flex;
  height: 110px;
  flex: none;
  z-index: 1;

  .arrow {
    margin-top: 30px;

    cursor: pointer;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    flex: none;

    &.left {
      background-image: url('../Assets/ArrowLeft.svg');
      &:hover {
        background-image: url('../Assets/ArrowLeftActive.svg');
      }
    }
    &.right {
      background-image: url('../Assets/ArrowRight.svg');
      &:hover {
        background-image: url('../Assets/ArrowRightActive.svg');
      }
    }
  }
}

.booking-times-list {
  display: flex;
  flex-direction: column;
  max-width: 100vw;

  .not-available {
    color: $gray-color;
  }

  .dates-list {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    box-sizing: border-box;
    width: 100%;

    overflow-x: scroll;
    position: sticky;
    top: $header-height;
    background: #ffffff;
    z-index: 1;

    .date-month {
      margin-right: 10px;

      &:last-child {
        margin-right: 0px;
      }

      .month {
        position: sticky;
        left: $base-padding;
        margin-left: 0px;
        margin-bottom: 15px;
        align-self: flex-start;
        color: $base-color;
        font-size: 16px;
      }

      &:first-child .date-day:first-child {
        margin-left: $base-padding;
      }

      &:last-child .date-day:last-child {
        margin-right: $base-padding;
      }

      .days {
        height: 60px;
        display: flex;
        align-items: center;

        .date-day {
          user-select: none;
          scroll-margin-left: $base-padding;
          -webkit-tap-highlight-color: transparent;
          cursor: pointer;
          flex: none;
          border-radius: 5px;
          margin-right: 10px;
          box-sizing: border-box;
          background: #eff4f9;
          width: 80px;
          max-height: 50px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &.selected {
            max-height: 60px;
            transition: max-height 0.1s ease-in, background 0.1s ease-in,
              font-size 0.1s ease-in;
          }

          .weekday {
            font-size: 14px;
          }
          .day {
            font-size: 16px;
            font-weight: bold;
          }

          &:hover:not(.selected) {
            opacity: 0.7;
            border: 1px solid $gray-color;
          }
          &:last-child {
            margin-right: 0px;
          }

          &:not(.available) {
            background-color: #eff1f6;
          }
          &:not(.available) .text {
            color: rgba($gray-color, 0.5);
          }

          &.selected .weekday {
            font-size: 16px;
          }
          &.selected .day {
            font-size: 18px;
            font-weight: bold;
          }

          &.selected {
            background: $btn-blue-gradient;
          }
          &.selected .text {
            color: #ffffff;
          }
        }
      }
    }
  }

  .date-hour {
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    padding: 25px;
    border-bottom: 1px solid rgba($gray-color, 0.3);

    &:hover {
      background-color: rgba($gray-color, 0.1);
    }
    &:first-child {
      border-top: 1px solid rgba($gray-color, 0.3);
    }
    .time {
      font-weight: 500;
      font-size: 18px;
      color: $base-color;
    }
  }
}
